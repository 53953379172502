import Email from "./Email";
import moment from "moment";
import XLSX from "xlsx";
import slugify from "slugify";

const nameCol = "Name"
const descriptionCol = "Description"
const imageCol = "Image URL"
const genreCol = "Genre"
const yearCol = "Year"
const castCol = "Cast"
const directorCol = "Director"
const ratingCol = "Rating"
const videoCol = "Video"
const durationCol = "Duration"
const linkCol = "Click URL"

const standardHeaders = [
  nameCol,
  descriptionCol,
  imageCol,
  genreCol,
  yearCol,
  castCol,
  linkCol
]

export default class DataParser {

  static formatData(data, fileUrl){
    let movieData = []

    let workbook = XLSX.read(data, {
      type: 'buffer',
      cellDates: true,
      cellText: false,
      dateNF: 'DD"/"MM"/"YYYY'
    })

    let workSheet = workbook.Sheets[workbook.SheetNames[0]]

    let rows = XLSX.utils.sheet_to_json(workSheet, { header: 1, raw: true})

    let headers = Array.from(rows)[0]
    rows = Array.from(rows).slice(1)

    rows.filter(entry => entry.length > 0).map((row, index) => {
      let name = row[headers.indexOf(nameCol)];
      let description = row[headers.indexOf(descriptionCol)]
      let cast = row[headers.indexOf(castCol)];
      let genre = row[headers.indexOf(genreCol)];
      let year = row[headers.indexOf(yearCol)];
      let image = row[headers.indexOf(imageCol)];
      let link = row[headers.indexOf(linkCol)] || `/data/${slugify(row[headers.indexOf(nameCol)])}/${btoa(`${index}&${DataParser.encodeUrl(fileUrl)}`)}`;
      let director = row[headers.indexOf(directorCol)];
      let rating = row[headers.indexOf(ratingCol)];
      let video = row[headers.indexOf(videoCol)];
      let duration = row[headers.indexOf(durationCol)];

      let fixedData = {
        name,
        description,
        image,
        genre,
        year,
        cast,
        link,
        director,
        rating,
        video,
        duration
      };

      fixedData = Object.assign({}, fixedData)

      movieData.push(fixedData);
    })
    headers = [
      nameCol,
      descriptionCol,
      imageCol,
      genreCol,
      yearCol,
      castCol,
      linkCol
    ]

    return [headers, movieData]
  }

  static dataToWorkbook(headers, rows){
    const workbook = XLSX.utils.book_new()
    let worksheet = XLSX.utils.json_to_sheet(rows)
    XLSX.utils.book_append_sheet(workbook, worksheet, "Data")

    let file = XLSX.writeFile(workbook, "Data.xlsx")
    return file
  }

  static stringToBoolean(string){
    if(string == null){
      return false
    }
    if(typeof string === "boolean"){
      return string
    }
    switch(string.toLowerCase().trim()){
      case "true": case "yes": case "1": return true;
      case "false": case "no": case "0": case null: return false;
      default: return Boolean(string);
    }
  }

  static paginateData(data, pageSize=4, pageNumber){
    return data.slice((pageNumber - 1) * pageSize, pageNumber * pageSize);
  }

  static getSimilar(movieData, index){
    return movieData.filter(entry => (entry.genre === movieData[index].genre && entry.name !== movieData[index].name))
  }

  static encodeUrl(fileUrl){
    return `${fileUrl.split('/')[2].split('.')[0]}--${fileUrl.split('/')[4].split('.')[0]}`
  }

  static decodeUrl(param, type){
    if(type === 'index'){
      return atob(param).split('&')[0]
    }else{
      return `https://${atob(param).split('&')[1].split('--')[0]}.cloudfront.net/files/${atob(param).split('&')[1].split('--')[1]}.xlsx`
    }

  }

}
