import React from "react";

import Collapsible from "react-collapsible";

import moment from "moment";

import Button from "../Button";
import Border from "../Border";
import Play from "../Play";

import Dropdown from "../Dropdown";

import ImageUploader from "./editable/ImageUploader";
import GradientPicker from "./editable/GradientPicker";

import {
  DEFAULT_PRIMARY_BUTTON,
  DEFAULT_SECONDARY_BUTTON,
} from "./editable/Section";

import H1 from "./editable/H1";
import P from "./editable/P";
import Img, { Sizes } from "./editable/Img";
import Section from "./editable/Section";

import CountdownTimer from "../CountdownTimer";

import General from "../../utils/General";
import Backend from "../../utils/Backend";

let BANNER_ALIGNMENT = [
  {
    value: "center",
    label: "Align Banner Content Center",
  },
  {
    value: "left",
    label: "Align Banner Content Left",
  },
  {
    value: "right",
    label: "Align Banner Content Right",
  }
];

let BACKGROUND_MODES = [
  {
    value: "image",
    label: "Background Image",
  },
  {
    value: "video",
    label: "Background Video",
  },
  {
    value: "color",
    label: "Background Colour",
  },
];

let BANNER_HEIGHTS = [
  {
    value: "full",
    label: "Full Height",
  },
  {
    value: "half",
    label: "Medium Height",
  },
];

let TITLE_SIZES = [
  {
    value: "5em",
    label: "Extra Large",
  },
  {
    value: "4.5em",
    label: "Large",
  },
  {
    value: "4em",
    label: "Medium",
  },
  {
    value: "3.5em",
    label: "Small",
  },
  {
    value: "3em",
    label: "Extra Small",
  },
];

let IMAGE_SIZES = [
  {
    value: 'small',
    label: "Small"
  },
  {
    value: 'medium',
    label: "Medium"
  },
  {
    value: 'large',
    label: "Large"
  }
];

const TEMPLATE_VIDEO = { url: "https://www.youtube.com/watch?v=DGQwd1_dpuc" };

export default class Block41 extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: props.data,
      editable: props.editable,
      windowWidth: window.innerWidth,
    };

    this.updateContentBlock = General.debounce(
      () => {
        Backend.updateContentBlock(this.state.data);
      },
      1000,
      false
    );
  }

  _handleResize = (e) => {
    this.setState({ windowWidth: window.innerWidth });
    setTimeout(() => {
      General.updateBackgroundImageHolder();
      General.updateLazyBackgroundImages();
    }, 100);
  };

  componentDidMount() {
    window.addEventListener("resize", this._handleResize);
  }

  componentWillUnmount() {
    window.addEventListener("resize", this._handleResize);
  }

  componentWillReceiveProps(nextProps) {
    this.setState(nextProps);
  }

  _getModalContent(data) {
    let { products, categories } = this.state;
    let alignment = data.extra?.alignment || "center"
    return (
      <>
        <Border>
          <Collapsible trigger="General Banner Options">
            <br></br>
            {this._renderToggle("foreground_image", "Image Inline Heading", {
              type: "photo",
              original:
                "https://d2tbwlwytqydh4.cloudfront.net/companies/1/websites/images/original_image_18-08-2020_12:03:40.png",
            })}
            {this._renderToggle(
              "countdown_timer",
              "Countdown Timer",
              moment().add("days", 7).toDate().toISOString(),
              null,
              () => General.updateCountdownTimer()
            )}
            {this._renderToggle("video_button", "Large Video Button", {
              url: "https://www.youtube.com/watch?v=DGQwd1_dpuc",
              settings: {},
            })}
            {this._renderToggle("auxiliary_button", "Small Video Button", {
              title: "Watch Quick Video",
              url: "https://www.youtube.com/watch?v=DGQwd1_dpuc",
              settings: { primary_color: "#FFFFFF", msc_enabled: true },
            })}
            {this._renderToggle(
              "primary_button",
              "Primary Button",
              DEFAULT_PRIMARY_BUTTON
            )}
            {this._renderToggle(
              "secondary_button",
              "Secondary Button",
              DEFAULT_SECONDARY_BUTTON
            )}
            {this._renderToggle("link_button", "Text CTA Button", {
              title: "GET IN TOUCH",
              url: "#",
              settings: { msc_enabled: true },
            })}
            {this._renderToggle(
              "parallax",
              "Parallax Effect (On Background Image)",
              true,
              false,
              () => General.updateParallax()
            )}
            {this._renderToggle(
              "gradient",
              "Gradient Effect (On Background Image)",
              true,
              false,
              () => General.updateGradients(),
              (e) => {
                data.gradient = e.target.checked;
                if (data.gradient) {
                  data.settings.overlay = null;
                }
                this._update(data, () => General.updateGradients());
              }
            )}
            {this._renderToggle(
              "overlay",
              "Dark Overlay Effect (On Background Image)",
              true,
              false,
              () => General.updateGradients(),
              (e) => {
                data.settings.overlay = e.target.checked ? 5 : null;
                if (e.target.checked) {
                  data.gradient = false;
                }
                this._update(data, () => General.updateGradients());
              }
            )}
          </Collapsible>
        </Border>

        {data.title && (
          <Border>
            <Collapsible trigger="Hero Heading Text Size">
              <br></br>
              <span>Large Text Heading Size</span>
              <Dropdown
                options={TITLE_SIZES}
                value={TITLE_SIZES.find(
                  (mode) => mode.value === (data.variation || "4.5em")
                )}
                onChange={(option) => {
                  data.variation = option.value;
                  this._update(data);
                }}
                placeholder="Select Size"
              />
            </Collapsible>
          </Border>
        )}

        <Border>
          <Collapsible trigger="Banner Height & Background">
            <br></br>
            <span>Background Type</span>
            <Dropdown
              options={BACKGROUND_MODES}
              value={BACKGROUND_MODES.find(
                (mode) => mode.value == data.background_mode
              )}
              onChange={(option) => {
                data.background_mode = option.value;

                if (
                  data.background_mode == "video" &&
                  data.background_video == null
                ) {
                  data.background_video = TEMPLATE_VIDEO;
                }
                this._update(data, () => {
                  General.updateImageBackgrounds();
                  General.updateVideo();
                });
              }}
              placeholder="Background Type"
            />
            <br />
            <br />
            {data.background_mode === "video" && (
              <>
                <span>Desktop Youtube Video URL</span>
                <input
                  type="text"
                  defaultValue={
                    data.background_video ? data.background_video.url : null
                  }
                  onChange={(event) => {
                    data.background_video =
                      data.background_video || TEMPLATE_VIDEO;
                    data.background_video.url = event.target.value;
                    this._update(data, () => {
                      General.updateVideo();
                    });
                  }}
                />
                <br />
                <br />
                <span>Mobile Youtube Video URL</span>
                <input
                  type="text"
                  defaultValue={
                    data.extra?.mobile_background_video ? data.extra?.mobile_background_video.url : null
                  }
                  onChange={(event) => {
                    if(!data.extra){
                      data.extra = {}
                    }
                    data.extra.mobile_background_video =
                      data.extra?.mobile_background_video || TEMPLATE_VIDEO;
                    data.extra.mobile_background_video.url = event.target.value;
                    this._update(data, () => {
                      General.updateVideo();
                    });
                  }}
                />
                <br />
                <br />
              </>
            )}
            {data.background_mode === "image" && (
              <>
                <ImageUploader
                  title={"Background Image"}
                  titleTag="span"
                  src={data.background_image?.original}
                  onUploaded={(image) => {
                    data.background_image = image;
                    this._update(data, () => General.updateImageBackgrounds());
                  }}
                />
                <br />
                <br />
                <ImageUploader
                  title={"Background Image For Mobile"}
                  titleTag="span"
                  src={data.extra?.mobile_background_image?.original || data.background_image?.original}
                  onUploaded={(image) => {
                    if(!data.extra){
                      data.extra = {}
                    }
                    data.extra.mobile_background_image = image;
                    this._update(data, () => General.updateImageBackgrounds());
                  }}
                />
                <br />
                <br />
              </>
            )}
            <span>Banner Height</span>
            <Dropdown
              options={BANNER_HEIGHTS}
              value={BANNER_HEIGHTS.find((mode) => mode.value == data.height)}
              onChange={(option) => {
                data.height = option.value;
                this._update(data, () => General.updateImageBackgrounds());
              }}
              placeholder="Banner Height"
            />
          </Collapsible>
        </Border>

        <Border>
          <Collapsible trigger="Banner Alignment">
            <br></br>
            <span>Select Alignment</span>
            <Dropdown
              options={BANNER_ALIGNMENT}
              value={BANNER_ALIGNMENT.find((mode) => mode.value == alignment)}
              onChange={(option) => {
                if(!data.extra){
                  data.extra = {}
                }
                data.extra.alignment = option.value
                this._update(data);
              }}
              placeholder="Select Alignment"
            />
          </Collapsible>
        </Border>

      </>
    );
  }

  _getModalBottomContent() {
    let { data } = this.state;

    if (!data.gradient) {
      return null;
    }

    return (
      <Border>
        <Collapsible trigger="Gradient">
          <br></br>
          <GradientPicker
            gradient={data.background_gradient}
            onUpdated={(colors) => {
              if (colors.length > 0) {
                colors = colors.join(",");
              } else {
                colors = null;
              }
              data.background_gradient = colors;
              this._update(data, () => General.updateGradients());
            }}
          />
        </Collapsible>
      </Border>
    );
  }

  _update(data, callback) {
    this.setState({ data }, () => {
      if (callback) {
        callback();
      }
      this.updateContentBlock();
    });
  }

  _renderImageModalContent(){
    let { data } = this.state;

    let selectedSize = data.extra?.foreground_image_size || 'medium'
    let size = IMAGE_SIZES.find(size => size.value === selectedSize)

    return (
      <>
        <Border>
          <Collapsible
            trigger="Image Layout"
          >
            <br></br>
            <span>Size</span>
            <Dropdown
              options={IMAGE_SIZES}
              value={size}
              onChange={option => {
                if(!data.extra){
                  data.extra = {}
                }
                data.extra.foreground_image_size = option.value
                this._update(data);
              }}
              placeholder="Size"
            />
          </Collapsible>
        </Border>
      </>
    )
  }

  _renderToggle(
    key,
    title,
    defaultOnValue,
    defaultOffValue = null,
    callback = null,
    onChange = null
  ) {
    let { data } = this.state;

    let checked = data[key] != null && data[key] != false;
    if (key == "overlay") {
      checked = data.settings.overlay > 0;
    }

    let labelId = "tg_" + data.id + "__" + key + "__cb_pb";
    return (
      <div class="row">
        <div class="col-md-9">
          <h4>{title}</h4>
        </div>
        <div class="col-md-3 text-right">
          <div class="input-checkbox input-checkbox--switch">
            <input
              id={labelId}
              type="checkbox"
              checked={checked}
              onChange={(e) => {
                if (onChange) {
                  onChange(e);
                  return;
                }
                data[key] = e.target.checked ? defaultOnValue : defaultOffValue;
                this._update(data, callback);
              }}
            />
            <label for={labelId}></label>
          </div>
        </div>
      </div>
    );
  }

  _renderBackgroundImage(){
    let { data, windowWidth } = this.state;

    if(data.background_mode !== "image"){
      return
    }

    let isMobile = windowWidth < 768

    let backgroundImageClassName =
      data.background_mode === "video" ? "yt-container " : "";
    backgroundImageClassName += "background-image-holder lazy-background";

    let image = data.background_image
    let size = Sizes.LARGE

    if(isMobile){
      image = data.extra?.mobile_background_image || data.background_image
      size = Sizes.SMALL
    }

    return (
      <>
        <div className={backgroundImageClassName}>
          <Img
            key={image.id}
            img={image}
            size={size}
            alt="background"
            block={"41"}
            priority={this.props.index}
          />
        </div>
      </>
    )
  }

  _renderContent() {
    let { data, editable, windowWidth } = this.state;

    data.settings.quaternary_color = data.settings.msc_enabled
      ? window.CURRENT_PAGE_PRIMARY_COLOR
      : data.settings.quaternary_color;

    let alignment = data.extra?.alignment || "center"
    let blockAlignment = "col-md-9"
    if(alignment === 'left' || alignment === 'right'){
      blockAlignment = "col-md-12"
    }

    return (
      <>

        {this._renderBackgroundImage()}

        {data.settings.overlay > 0 && (
          <div className="imagebg" data-overlay={data.settings.overlay} />
        )}
        <div className="container pos-vertical-center">
          <div className="row">
            <div
              className={`${blockAlignment}`}
            >
              {data.foreground_image && (
                <Img
                  img={data.foreground_image}
                  src={data.extra?.foreground_image_size || 'medium'}
                  block={41}
                  priority={this.props.index}
                  alt={""}
                  className={"banner_logo s-" + data.extra?.foreground_image_size}
                  renderModalContent={() => this._renderImageModalContent()}
                  editable={editable}
                  type={data.foreground_image.type}
                  pickerType={"illustration"}
                  data={data}
                  primaryColor={data.settings.quaternary_color}
                  onUpdated={(image) => {
                    data.foreground_image = image;
                    this._update(data);
                  }}
                  onColorUpdated={(color) => {
                    data.settings.quaternary_color = color;
                    data.settings.msc_enabled = false;
                    this._update(data);
                  }}
                  onBlockUpdated={data => {
                    this._update(data);
                  }}
                />
              )}

              {data.title && (
                <>

                  <div className="c-tool-tips block two">
                    {General.renderTourButtonTitle("top", "Click To Edit")}
                  </div>

                  <H1
                    key={data.variation || "title"}
                    text={data.title}
                    color={data.title_color}
                    textSize={data.variation}
                    className="banner-h1"
                    editable={editable}
                    textAlign={alignment}
                    onUpdated={(text) => {
                      data.title = text;
                      this._update(data);
                    }}
                  />

                </>
              )}

              {data.countdown_timer && (
                <CountdownTimer
                  time={data.countdown_timer}
                  color={data.settings.primary_color}
                  editable={editable}
                  onUpdated={(time) => {
                    data.countdown_timer = time;
                    this._update(data);
                  }}
                />
              )}

              {data.subtitle && (
                <P
                  text={data.subtitle}
                  color={data.subtitle_color}
                  editable={editable}
                  textAlign={alignment}
                  onUpdated={(text) => {
                    data.subtitle = text;
                    this._update(data);
                  }}
                />
              )}

              {data.video_button && (
                <Play
                  overlay={data.video_button.settings.overlay}
                  icon={data.video_button.icon}
                  iconImage={data.video_button.icon_image}
                  videoUrl={data.video_button.url}
                  editable={editable}
                  onUpdated={(videoUrl, icon, iconImage, overlay) => {
                    data.video_button.url = videoUrl;
                    data.video_button.icon = icon;
                    data.video_button.icon_image = iconImage;
                    data.video_button.settings.overlay = overlay;
                    this._update(data);
                  }}
                />
              )}

              <div
                className={`c-btn-group`}
              >
                <Button
                  data={data.secondary_button}
                  type="secondary"
                  editable={editable}
                  renderModal={(content) => this.props.renderModal(content)}
                  onUpdated={(button) => {
                    data.secondary_button = button;
                    this._update(data);
                  }}
                />

                <Button
                  data={data.primary_button}
                  editable={editable}
                  renderModal={(content) => this.props.renderModal(content)}
                  onUpdated={(button) => {
                    data.primary_button = button;
                    this._update(data);
                  }}
                />
              </div>

              {data.link_button && (
                <span className="block type--fine-print">
                  <Button
                    data={data.link_button}
                    editable={editable}
                    type="text_underline"
                    onUpdated={(button) => {
                      data.link_button = button;
                      this._update(data);
                    }}
                  />
                </span>
              )}

              {data.auxiliary_button && (
                <div className="inside-btn-inline">
                  <Button
                    data={data.auxiliary_button}
                    type="video"
                    hideIcon={true}
                    showYoutubeURL={true}
                    editable={editable}
                    onUpdated={(button) => {
                      data.auxiliary_button = button;
                      this._update(data);
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </>
    );
  }

  _renderContainer() {
    let { data, editable, windowWidth } = this.state;

    let isMobile = windowWidth < 768

    let videoUrl = null

    if(isMobile){
      videoUrl = data.extra?.mobile_background_video ? data.extra?.mobile_background_video.url : null
    }else{
      videoUrl = data.background_video ? data.background_video.url : null;
    }

    let videoId = General.getYoutubeVideoId(videoUrl);

    if (videoId && data.background_mode == "video") {
      const opts = {
        height: "100vh",
        width: "100vh",
        playerVars: {
          autoplay: 1,
        },
      };

      return (
        <>
          {/* display in above 1024px resolutions */}
          <div className="yt-container">
            <div className="yt-wrapper">
              <iframe
                key={videoId}
                width="100%"
                height="100%"
                src={`https://www.youtube.com/embed/${videoId}?controls=0&amp;autoplay=1&showinfo=0&mute=1&loop=1&playlist=${videoId}`}
                frameborder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              />
              <div className="yt-mask" />
            </div>
          </div>

          {this._renderContent()}
        </>
      );
    }

    return this._renderContent();
  }

  render() {
    let { data, editable } = this.state;

    let className = data.parallax ? "parallax" : "";
    if (data.height == "full") {
      className += " height-100";
    }
    if (data.height == "half") {
      className += " height-60 height-half";
    }

    let alignment = data.extra?.alignment || "center"
    let blockAlignment = "text-center"
    if(alignment === 'left'){
      blockAlignment = "text-left"
    }else if(alignment === 'right'){
      blockAlignment = "text-right"
    }

    let primaryColor=false
    if(data.countdown_timer){
      primaryColor=true
    }

    return (
      <Section
        data={data}
        index={this.props.index}
        className={`block-41 imagebg ${blockAlignment} block-align-${alignment} event-banner ${className} ${this.props.className}`}
        editable={editable}
        onUpdated={(data) => this._update(data)}
        onDelete={() => this.props.onDelete()}
        primaryColorTitle={"Countdown Timer Colour"}
        primaryColor={primaryColor}
        secondaryColor={false}
        onAddContentBlockPressed={(contentBlock) =>
          this.props.onAddContentBlockPressed(contentBlock)
        }
        image={data.background_image}
        imageTitle={"Background Image"}
        renderModalContent={() => this._getModalContent(data)}
        renderModalBottomContent={() => this._getModalBottomContent(data)}
        onUpdateSettings={(settings) => {
          data.settings = settings;
          this._update(data);
        }}
        canMoveUp={this.props.canMoveUp}
        canMoveDown={this.props.canMoveDown}
        onVisibilityButtonPressed={(index) => this.props.onVisibilityButtonPressed(index)}
        onMoveUpPressed={() => this.props.onMoveUpPressed()}
        onMoveDownPressed={() => this.props.onMoveDownPressed()}
      >
        {this._renderContainer()}
      </Section>
    );
  }
}
