import React from 'react'

import General from '../utils/General'
import ScriptCache from '../utils/ScriptCache'

import '../assets/css/metronic/pages/error/error-6.css'
import '../assets/css/metronic/style.bundle.css'

const IMG_NOT_FOUND = require("../assets/img/NotFound.jpg")

export default class NotFound extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      website: props.website,
      loading: true
    }
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  _loadJs(){
    ScriptCache.loadDefaults()
    setTimeout(() => {
      General.updateAll()
    }, 1000)
  }


  render(){
    let {
      website
    } = this.state

    let homeUrl = website ? "/" : "https://website-builder.ie"
    return (
      <div className="css-metronic">

        <div className="kt-grid kt-grid--ver kt-grid--root cover-fullscreen">
          <div className="kt-grid__item kt-grid__item--fluid kt-grid  kt-error-v6" style={{backgroundImage: `url(${IMG_NOT_FOUND})` }} >

            <div className="kt-error_container">
              <div className="kt-error_subtitle kt-font-light">
                <h1 className="text-white" style={{ marginTop: "20px" }}>Ooops...</h1>
              </div>
              <p className="kt-error_description kt-font-light text-center text-white mt-3 mb-4">
                Looks like something went wrong.<br/>We're working on it
              </p>
              <div className="c-btn-group btn-group-not-found">
                <a className="btn btn-primary btn-wide btn-boldest" href={homeUrl}>Home</a>
              </div>
            </div>

          </div>
        </div>

      </div>
      )
    }
}
