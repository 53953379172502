import AuthManager from './AuthManager'
import General from "./General";
// returns an authenticated fetch requests if possible
// these method are just shortcuts for including headers into
// fetch requests
const PAGE_LIMIT = 20
export default class FetchHelper {
  static get(endpoint, validateTokens = true) {
    let data = {}
    let statusCode = null

    return AuthManager.setTokens()
      .then(() => {
        data.headers = AuthManager.getHeaders()
        return data
      })
      .then(() => FetchHelper._handleValidateTokens(validateTokens))
      .then(() => fetch(endpoint, data))
      .then(response => {
        statusCode = response.status
        if (statusCode == 204) {
          return response
        }
        return response.json()
      })
      .then(responseJson => {
        let status = { code: statusCode, success: responseJson.status }
        if (this._hasError(status)) {
          throw FetchHelper._getError(responseJson)
          return
        }

        return responseJson
      })
  }

  static post(endpoint, data, isMultiPart = false, validateTokens = true) {
    let statusCode = null
    return FetchHelper._handleValidateTokens(validateTokens)
      .then(() => fetch(endpoint, {
        method: 'POST',
        headers: isMultiPart
          ? AuthManager.getHeaders('multipart/form-data')
          : AuthManager.getHeaders(),
        body: isMultiPart ? data : JSON.stringify(data),
      }))
      .then(response => {
        statusCode = response.status
        if (statusCode == 204) {
          return response
        }
        return response.json()
      })
      .then(responseJson => {
        let status = { code: statusCode, success: responseJson.status }
        if (this._hasError(status)) {
          throw FetchHelper._getError(responseJson)
          return
        }
        return responseJson
      })
  }

  static _handleValidateTokens(validateTokens) {
    if (validateTokens) {
      return AuthManager.validateTokens()
    }

    return new Promise((resolve, reject) => {
      resolve()
    })
  }

  static patch(endpoint, data, stringify = true, validateTokens = true) {
    var headers = AuthManager.getHeaders()
    if (stringify) {
      data = JSON.stringify(data)
    } else {
      headers = AuthManager.getHeaders('multipart/form-data')
    }

    let statusCode = null
    return FetchHelper._handleValidateTokens(validateTokens)
      .then(() => fetch(endpoint, {
        method: 'PATCH',
        headers: headers,
        body: data
      }))
      .then(response => {
        statusCode = response.status
        if (statusCode == 204) {
          return response
        }
        return response.json()
      })
      .then(responseJson => {
        let status = { code: statusCode, success: responseJson.status }
        if (this._hasError(status)) {
          throw FetchHelper._getError(responseJson)
        }
        return responseJson
      })
  }

  static put(endpoint, data, stringify = true, validateTokens = true) {
    var headers = AuthManager.getHeaders()
    if (stringify) {
      data = JSON.stringify(data)
    } else {
      headers = AuthManager.getHeaders('multipart/form-data')
    }
    let statusCode = null
    return FetchHelper._handleValidateTokens(validateTokens).then(() => {
      return fetch(endpoint, {
        // this is needed by server side for all put requests
        method: 'PUT',
        headers: headers,
        body: data
      })
        .then(response => {
          statusCode = response.status
          return response.json()
        })
        .then(responseJson => {
          let status = { code: statusCode, success: responseJson.status }
          if (this._hasError(status)) {
            throw FetchHelper._getError(responseJson)
          }

          return responseJson
        })
    })
  }

  static delete(endpoint, data, validateTokens = true) {
    let statusCode = null
    return FetchHelper._handleValidateTokens(validateTokens)
      .then(() => fetch(endpoint, {
        method: 'DELETE',
        headers: AuthManager.getHeaders(),
        body: JSON.stringify(data)
      }))
      .then(response => {
        statusCode = response.status
        if (statusCode == 204) {
          return response
        }
        return response.json()
      })
      .then(responseJson => {
        let status = { code: statusCode, success: responseJson.status }
        if (this._hasError(status)) {
          throw FetchHelper._getError(responseJson)
          return
        }
        return responseJson
      })
  }

  static _hasError({ code, success }) {
    return code < 200 || code > 299 || success == false
  }

  static _getError(responseJson) {
    let error = null
    if (responseJson.message) {
      error = responseJson.message
    } else if (responseJson.non_field_errors) {
      error = responseJson.non_field_errors
    } else {
      error = responseJson
    }

    if (error.constructor === Object) {
      error = FetchHelper._parseError(error[Object.keys(error)[0]], Object.keys(error)[0])
    }

    let message = 'An unexpected error occured'

    if (error) {
      message = error
    }

    let data = { error: error, message: message }
    if(responseJson.redirect_url){
      data.redirect_url = responseJson.redirect_url
    }
    return data
  }

  static _parseError(error, key){
    if (error instanceof Array) {
      if(error[0] instanceof Object){
        return FetchHelper._parseError(error[0])
      }
      return `${General.snakeCaseToTitleCase(key)}: ${error[0]}`
    } else if (typeof error === 'string') {
      return `${General.snakeCaseToTitleCase(key)}: ${error}`
    } else if(error instanceof Object){
      return FetchHelper._parseError(error[Object.keys(error)[0]], Object.keys(error)[0])
    }
  }

  static _hasMore(results, pageLimit) {
    if (results.current_page) {
      return results.current_page < results.last_page
    }
    return results.data.length >= pageLimit
  }
}
