import React from "react";

import $ from "jquery";

import Form from "./form/Form";

import Event from "../utils/Event";
import Notify from "../utils/Notify";
import Backend from "../utils/Backend";

export default class PaymentWidget extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      website: props.website,
      editable: props.editable,
      open: false,
      form: null,
    };

  }

  componentDidMount(){
    let { website } = this.state
    Backend.getPaymentForm(website.id)
    .then(forms => {
      if(forms.length == 0){
        return
      }
      this.setState({
        form: forms[0],
      })
    }).catch(e => {
      Notify.error(e.message)
    })

    Event.on("CLOSE_PAYMENT_WIDGET", () => {
      this.setState({ open: false }, () => this._toggle());
    })
  }

  _toggle(){
    let { open } = this.state;

    if (open == true && $(document).width() < 994) {
      // disable the parent/body scroll
      $("body").addClass("disable-scroll");

      // Hide the Chat Footer, when virtual keyboard is on in the Mobiles
      var _originalSize = $(window).width() + $(window).height();
      $(window).resize(function () {
        if ($(window).width() + $(window).height() != _originalSize) {
          $(".c-widgets-footer").addClass("d-none");
        } else {
          $(".c-widgets-footer").removeClass("d-none");
        }
      });
    } else if ($("body").hasClass("disable-scroll")) {
      $("body").removeClass("disable-scroll");
    }
  }

  render() {
    let { open, website, editable, form } = this.state;

    if (window.HIDE_PAYMENT_WIDGET || !form) {
      return null;
    }

    let favIconUrl = website.favicon
      ? website.favicon.original
      : "/favicon.png";

    let cWidgetsActiveClassName = open ? "open" : "close";

    let data = {
      settings: {
        senary_color: website.primary_color,
        quinary_color: "white"
      }
    }

    return (
      <div
        className={`c-widgets payment main-container ${cWidgetsActiveClassName}`}
      >

        <div
          className="bg-mask"
          onClick={(e) => this.setState({ open: !open }, () => this._toggle())}
        />

        <div className="c-widgets-box">
          <div className="c-widgets-head p-4 text-white">
            <div className="row justify-content-center align-items-center">
              <div className="col-auto logo pr-0">
                <img src={favIconUrl} />
              </div>
              <div className="col name">
                <h3 className="m-0 text-white">{website.name}</h3>
              </div>
              <a
                className="col-auto text-white close material-icons"
                href="javascript:void(0)"
                onClick={(e) => this.setState({ open: !open }, () => this._toggle())}
              >
                close
              </a>
            </div>

            {
              Object.keys(window.CURRENT_WEBSITE.payment_method).length > 0 &&
              <div className="row title-text mt-4">
                <div className="col">
                  <p>Please complete the below to make a payment.</p>
                </div>
              </div>
            }

          </div>

          <div className="c-widgets-body">
            <div className="d-flex flex-column payment-form">


              <div className={`form-crm ${!window.CURRENT_WEBSITE_SHOP ? 'inactive' : 'active'}`}>

                { !window.CURRENT_WEBSITE_SHOP &&
                  <div className="row title-text mt-2 mb-2">
                    <div className="col">
                      <p className="text-center font-weight-bold payment-widget-prompt">
                        {window.CURRENT_WEBSITE.name} has not enabled payments.
                      </p>
                    </div>
                  </div>
                }

                <Form
                  data={data}
                  form={form}
                  onAsyncPaymentFailed={() => this.setState({ open: true })}
                />

              </div>

              <div className="c-widgets-footer d-flex justify-content-center align-items-center">
                Powered By &nbsp;
                <a
                  className="link--primary fw-4"
                  href="https://website-builder.ie/"
                  target="_blank"
                >
                  Website Builder
                </a>
              </div>

            </div>
          </div>
        </div>

        <a
          className="c-widgets-button"
          href="javascript:void(0)"
          onClick={(e) => this.setState({ open: !open })}
        >
          <span className="material-icons-outlined default">credit_card</span>
          <span className="material-icons close">close</span>
          { form.name }
        </a>

      </div>
    );
  }
}
